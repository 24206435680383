<template>
<div>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="blue"
          >Datos básicos &nbsp;&nbsp;&nbsp;<a href="#" class="btn btn-xs btn-primary" v-b-modal.modal-prevent-closing><b-icon icon="person-plus-fill"></b-icon></a></b-button>
      </b-card-header>
      <b-collapse id="accordion-1" :visible="info.data ? true : false" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-collapse
        id="accordion-1"
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <b-card-text>
            <b-list-group v-for="value in info.data" :key="value.suscriptor">
              <b-list-group-item
                ><small class="text-info">Suscriptor: </small
                ><h2><span class="fw-bold text-primary">{{ value.suscriptor }}</span></h2>
                <a v-if="value.latitud !== null" :href="`https://www.google.com/maps/dir//${value.latitud},${value.longitud}/@${value.latitud},${value.longitud}data=!3m1!1e3?hl=es-ES`" target="_blank" class="btn btn-sm btn-primary">Ver en Google Maps</a>
                </b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Nombre: </small
                >{{ value.nombre }} {{ value.apellido }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Identificación: </small
                >{{ value.identificacion }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Dirección: </small
                >{{ value.direccion }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Barrio: </small
                >{{ value.barrio }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Ruta lectura: </small
                >{{ value.rutalectura }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Celular: </small
                >{{ value.celular }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Teléfono: </small
                >{{ value.telefono }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Email: </small
                >{{ value.correoelectronico }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Plan: </small
                >{{ value.plan }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Estado: </small
                >{{ value.estadotecnico }}
                </b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Facturas con saldo: </small
                >{{ value.facturasconsaldo }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Saldo pendiente: </small
                >{{ value.saldopendiente }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Saldo Financiación: </small
                >{{ value.saldopdtefinanciacion }}
              </b-list-group-item>
              <b-list-group-item
                ><small class="text-info clearfix">Valor últ. pago: </small
                >{{ value.valorpago }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Fecha ult. pago: </small
                >{{ value.fechaultimopago }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Estrato: </small
                >{{ value.estrato }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Medidor: </small
                >{{ value.idmedidor }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Últ. Obs. lectura: </small
                >{{ value.obslectura }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Lectura actual: </small
                >{{ value.lecturaactual }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Lectura anterior: </small
                >{{ value.lecturaanterior }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Consumo actual: </small
                >{{ value.consumoactual }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Consumo promedio: </small
                >{{ value.consumopromedio }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Últimos consumos: </small>
                    <table
                    class="table striped table-sm table-bordered text-center"
                    >
                    <thead>
                        <tr>
                        <td><small>Mes 1</small></td>
                        <td><small>Mes 2</small></td>
                        <td><small>Mes 3</small></td>
                        <td><small>Mes 4</small></td>
                        <td><small>Mes 5</small></td>
                        <td><small>Mes 6</small></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td class="small">{{ value.consumomes1 }}</td>
                        <td class="small">{{ value.consumomes2 }}</td>
                        <td class="small">{{ value.consumomes3 }}</td>
                        <td class="small">{{ value.consumomes4 }}</td>
                        <td class="small">{{ value.consumomes5 }}</td>
                        <td>{{ value.consumomes6 }}</td>
                        </tr>
                    </tbody>
                    </table>
              </b-list-group-item>
              <b-list-group-item
                ><small class="text-info clearfix">Latitud: </small
                >{{ value.latitud }}</b-list-group-item
              >
              <b-list-group-item
                ><small class="text-info clearfix">Longitud: </small
                >{{ value.longitud }}</b-list-group-item
              >
            </b-list-group>
          </b-card-text>
        </b-card-body>
      </b-collapse>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="blue">Facturación (Últimas 12 facturas)</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div
              class="table-responsive"
              v-for="value in info.data"
              :key="value.suscriptor"
            >
              <b-table
                striped
                table-sm
                hover
                :items="value.facturas"
                :fields="fields"
              ></b-table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="blue">Pagos realizados (Últimos 12 pagos)</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div
              class="table-responsive"
              v-for="value in info.data"
              :key="value.suscriptor"
            >
              <b-table
                striped
                table-sm
                hover
                :items="value.recaudos"
                :fields="fieldsRecaudo"
              ></b-table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-4 variant="blue">Notas de descuento (Últimas 12)</b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div
              class="table-responsive"
              v-for="value in info.data"
              :key="value.suscriptor"
            >
              <b-table
                striped
                table-sm
                hover
                :items="value.notas_descuento"
                :fields="fieldsNotas"
              ></b-table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-5 variant="blue">Atenciones (Últimas 12)</b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div
              class="table-responsive"
              v-for="value in info.data"
              :key="value.suscriptor"
            >
              <b-table
                striped
                table-sm
                hover
                :items="value.atenciones"
                :fields="fieldsAtenciones"
              ></b-table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-6 variant="blue">Órdenes (Últimas 12)</b-button>
      </b-card-header>
      <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div
              class="table-responsive"
              v-for="value in info.data"
              :key="value.suscriptor"
            >
              <b-table
                striped
                table-sm
                hover
                :items="value.ordenes"
                :fields="fieldsOrdenes"
              ></b-table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-7 variant="blue">Gestión Lecturas (Últimas 6)</b-button>
      </b-card-header>
      <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text>
            <div
              class="table-responsive"
              v-for="value in info.data"
              :key="value.suscriptor"
            >
              <b-table
                striped
                table-sm
                hover
                :items="value.lecturas"
                :fields="fieldsLecturas"
              ></b-table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>


  </div>
</div>
</template>

<script>
export default {
  props: ["info"],

  data() {
    return {
      fields: [
        {
          key: "idcuentacobro",
          label: "Factura",
          sortable: true,
        },
        {
          key: "ano",
          label: "Año",
          sortable: true,
        },
        {
          key: "mes",
          label: "Mes",
          sortable: true,
        },
        {
          key: "fechaemision",
          label: "Fecha Generación",
          sortable: true,
        },
        {
          key: "valortotalfacturado",
          label: "Total factura",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "valorfacturadomes",
          label: "Valor Fact. Mes",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "facturasconsaldo",
          label: "Facturas con saldo",
          sortable: true,
        },
        {
          key: "valorsaldoanterior",
          label: "Saldo anterior",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "recargomora",
          label: "Recargo mora",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "saldoafavor",
          label: "Saldo a favor",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "vlrtotalfactconsacue",
          label: "Valor Acu",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "vlrtotalfactconsalca",
          label: "Valor Alc",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "facturacionaseoactual",
          label: "Valor Ase",
          sortable: true,
          formatter: this.formatter,
        },
      ],
      fieldsRecaudo: [
        {
          key: "cuponpago",
          label: "Cupón",
          sortable: true,
        },
        {
          key: "fechapago",
          label: "Fecha pago",
          sortable: true,
        },
        {
          key: "valorpago",
          label: "Valor pagado",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "conciliacion",
          label: "Conciliación",
          sortable: true,
        },
        {
          key: "banco",
          label: "Banco",
          sortable: true,
        },
        {
          key: "sucursal",
          label: "Sucursal",
          sortable: true,
        },
        {
          key: "fechagrabacion",
          label: "Fecha grabación",
          sortable: true,
        },
      ],
      fieldsOrdenes: [
        {
          key: "idorden",
          label: "Orden",
          sortable: true,
        },
        {
          key: "tipoorden",
          label: "Tipo orden",
          sortable: true,
        },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
        },
        {
          key: "fechageneracion",
          label: "Fecha Generación",
          sortable: true,
        },
        {
          key: "fechaejecucion",
          label: "Fecha ejecuc.",
          sortable: true,
        },
        {
          key: "fechalegalizacion",
          label: "Fecha Legalización",
          sortable: true,
        },
        {
          key: "razonlegalizacion",
          label: "Razón Legalización",
          sortable: true,
        },
        {
          key: "efectiva",
          label: "Efectiva",
          sortable: true,
        },
        {
          key: "observacionorden",
          label: "Observación",
          sortable: true,
        },
      ],
      fieldsAtenciones: [
        {
          key: "idatencion",
          label: "Atención",
          sortable: true,
        },
        {
          key: "tipo_atencion",
          label: "Tipo atención",
          sortable: true,
        },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
        },
        {
          key: "fechageneracion",
          label: "Fecha Generación",
          sortable: true,
        },
        {
          key: "fecharespuesta",
          label: "Fecha respuesta",
          sortable: true,
        },
        {
          key: "servicio",
          label: "Servicio",
          sortable: true,
        },
        {
          key: "causaatencion",
          label: "Causa Atención",
          sortable: true,
        }
      ],
      fieldsNotas: [
        {
          key: "notadescuento",
          label: "Nota descuento",
          sortable: true,
        },
        {
          key: "tipo_nota",
          label: "Tipo nota",
          sortable: true,
        },
        {
          key: "valor",
          label: "Valor nota",
          sortable: true,
          formatter: this.formatter,
        },
        {
          key: "fechageneracion",
          label: "Fecha Generación",
          sortable: true,
        },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
        },
        {
          key: "factura",
          label: "Factura",
          sortable: true,
        },
        {
          key: "observacion",
          label: "Observación",
          sortable: true,
        }
      ],
      fieldsLecturas: [
        {
          key: "ano",
          label: "Año",
          sortable: true,
        },
        {
          key: "mes",
          label: "Mes",
          sortable: true,
        },
        {
          key: "lecturatomada",
          label: "Lectura tomada",
          sortable: true,
        },
        {
          key: "descobservacionlectura",
          label: "Obs. Toma de lectura",
          sortable: true,
        },
        {
          key: "lecturafacturada",
          label: "Lectura Facturada",
          sortable: true,
        },
        {
          key: "observacionfacturada",
          label: "Obs. Facturada",
          sortable: true,
        },
        {
          key: "descobservacionfacturada",
          label: "Desc. Obs. Facturada",
          sortable: true,
        },
        {
          key: "consumofacturado",
          label: "Consumo F.",
          sortable: true,
        },
        {
          key: "pasacritica",
          label: "Crítica",
          sortable: true,
        },
        {
          key: "lecturaanterior",
          label: "Lectura Ant.",
          sortable: true,
        },
        {
          key: "fechamodificacion",
          label: "Ult. Fecha Mod.",
          sortable: true,
        }
      ],
    };
  },
  methods: {
    formatter: (value) => {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
};
</script>
