<template>
  <div>


    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Actualizar datos:"
      hide-footer
    >
      <b-form
        @submit.stop.prevent="onSubmit"
        v-for="value in info.data"
        :key="value.suscriptor"
      >
        <b-form-group
          id="identificacion-input-group-1"
          label="Identificación:"
          label-for="identificacion"
        >
          <b-form-input
            id="identificacion"
            name="identificacion"
            v-model="$v.form.identificacion.$model"
            :state="validateState('identificacion')"
            aria-describedby="identificacion-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="identificacion-live-feedback"
            >Identificación es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          id="celular-input-group-2"
          label="Celular:"
          label-for="celular"
        >
          <b-form-input
            id="celular"
            name="celular"
            v-model="$v.form.celular.$model"
            :state="validateState('celular')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback"
            >Celular obligatorio y debe ser solo
            números.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          id="telefono-input-group-2"
          label="Teléfono:"
          label-for="telefono"
        >
          <b-form-input
            id="telefono"
            name="telefono"
            v-model="$v.form.telefono.$model"
            :state="validateState('telefono')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback"
            >Teléfono obligatorio y debe ser solo
            números.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group id="email-input-group-2" label="Email:" label-for="email">
          <b-form-input
            id="email"
            name="email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback"
            >Correo electrónico obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>

        <div class="end-0 text-right">
          <b-button @click="$bvModal.hide('modal-prevent-closing')"
            >Cancelar</b-button
          >
          <b-button type="submit" class="end-0" variant="primary"
            >Guardar</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
    <script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  props: ["info"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        suscriptor: null,
        identificacion: null,
        celular: null,
        telefono: null,
        email: null,
      },
      matricula: ''
    };
  },
  watch: {
    info: function (newVal, oldVal) {
      //console.log('Prop changed: newVal ', newVal, ' | was: oldVal ', oldVal)
      if (this.info.data) {
        this.matricula = this.info.data[0].idsuscriptor;
        this.form.suscriptor = this.info.data[0].idsuscriptor;
        this.form.identificacion = this.info.data[0].identificacion;
        this.form.celular = this.info.data[0].celular;
        this.form.telefono = this.info.data[0].telefono;
        this.form.email = this.info.data[0].correoelectronico
            .toLowerCase()
            .trim();
      }
    },
  },
  validations: {
    form: {
      identificacion: {
        required,
        maxLength: maxLength(15),
      },
      celular: {
        required,
        maxLength: maxLength(45),
      },
      telefono: {
        required,
        maxLength: maxLength(25),
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        identificacion: null,
        celular: null,
        telefono: null,
        email: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      axios
        .post("/suscriptores", {
          suscriptor: this.form.suscriptor,
          identificacion: this.form.identificacion,
          celular: this.form.celular,
          telefono: this.form.telefono,
          email: this.form.email,
        })
        .then((response) => {
          //this.$emit('data', response);
          // this.dismissCountDown = this.dismissSecs
          this.$bvModal.hide('modal-prevent-closing');
          this.$emit("data", "Datos correctamente actualizados!");
          this.resetForm();
          //window.location.href = 'suscriptores';
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
    },
  },
};
</script>
