<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="blue"
          >Registro de información</b-button
        >
      </b-card-header>
      <b-card-body>
        <b-card-text>
          <form name="forminfo" method="POST" @submit.stop.prevent="onSubmit">
            <div class="row">
              <div class="col-md-2 mb-1">
                <input
                  type="number"
                  class="form-control"
                  name="matricula"
                  id="matricula"
                  min="1"
                  max="99999"
                  placeholder="Matrícula"
                  v-model="form.matricula"
                />
              </div>
              <div class="col-md-2 mb-1">
                <input
                  type="text"
                  class="form-control"
                  name="nombre"
                  id="nombre"
                  maxlength="100"
                  placeholder="Nombre"
                  v-model="form.nombre"
                />
              </div>
              <div class="col-md-2 mb-1">
                <input
                  type="text"
                  class="form-control"
                  name="identificacion"
                  id="identificacion"
                  maxlength="15"
                  placeholder="Documento"
                  v-model="form.identificacion"
                />
              </div>
              <div class="col-md-2 mb-1">
                <input
                  type="text"
                  class="form-control"
                  name="direccion"
                  id="direccion"
                  maxlength="100"
                  placeholder="Dirección"
                  v-model="form.direccion"
                />
              </div>
              <div class="col-md-2 mb-1">
                <input
                  type="text"
                  class="form-control"
                  name="telefono"
                  id="telefono"
                  maxlength="25"
                  placeholder="Teléfono"
                  v-model="form.telefono"
                />
              </div>
              <div class="col-md-1 mb-1">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    value="1"
                    class="custom-control-input"
                    name="propietario"
                    id="propietario"
                    v-model="form.propietario"
                  />
                  <label class="custom-control-label" for="propietario"
                    >Propietario</label
                  >
                </div>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  class="pull-right btn btn-success"
                  id="registrar"
                  @click="onSubmit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </b-card-text>
      </b-card-body>
    </b-card>
    <b-card no-body class="mb-1 mt-3">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="blue"
          >Datos registrados</b-button
        >
      </b-card-header>
      <b-card-body>
        <b-card-text>
          <div class="table-responsive">
            <b-table
              striped
              table-sm
              hover
              :items="infousuarios"
              :fields="fields"
              ref="table"
              refresh
              custom-row-key="id"
            >
              <template v-slot:cell()="{ value, item, field: { key } }">
                <template v-if="!item.isEditing">{{ value }}</template>
                <b-form-input v-else v-model="item[key]" />
              </template>

              <template v-slot:cell(actions)="{ item }">
                <b-button
                  variant="primary"
                  text="Actions"
                  @click="onEdit(item)"
                  >{{ item.isEditing ? "Guardar" : "Editar" }}</b-button
                >
              </template>
            </b-table>
          </div>
          <div class="overflow-auto">
              <b-pagination class="justify-content-center"
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                prev-text="Anterior"
                next-text="Siguiente"
                @change="handlePageChange"
            ></b-pagination>
            </div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      page: 1,
      count: 0,
      pageSize: 1,
      form: {
        matricula: "",
        nombre: null,
        identificacion: "",
        telefono: null,
        propietario: false,
      },
      output: null,
      fields: [
        {
          key: "matricula",
          label: "Matrícula",
          sortable: true,
        },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "identificacion",
          label: "Identificación",
          sortable: true,
        },
        {
          key: "direccion",
          label: "Dirección",
          sortable: true,
        },
        {
          key: "telefono",
          label: "Teléfono",
          sortable: true,
        },
        {
          key: "propietario",
          label: "Propietario",
          sortable: true,
          formatter: this.transform,
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
      infousuarios: [
          { matricula: 40, nombre: 'Dickerson', identificacion: 'Macdonald', telefono: "dsfs", propietario: "1" }
        ]
    };
  },
  watch: {
    infousuarios: function (newVal, oldVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    },
  },
  created: async function() {
        axios.get("/getData")
        .then((response) => {
            this.infousuarios = response.data.data;
            this.count = response.data.total;
            this.pageSize = response.data.per_page;
            this.page = response.data.current_page;
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
  },
  methods: {
    onEdit(item) {
      if (item.isEditing) {
        item.isEditing = false;
        axios
        .put("/info", {
          item
        })
        .then((response) => {
           // this.infousuarios.push(response.data);
            //this.refrescar()
          //window.location.href = "info";
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
      } else {
        this.$set(item, "isEditing", true);
        console.log(item)
      }
    },
    refrescar() {
      axios.get("/getData")
        .then((response) => {
            this.infousuarios = response.data.data;
            this.count = response.data.total;
            this.pageSize = response.data.per_page;
            this.page = response.data.current_page;
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
    },
    handlePageChange(value) {
      this.page = value;
      axios.get("/getData?page=" + this.page)
        .then((response) => {
            this.infousuarios = response.data.data;
            this.count = response.data.total;
            this.pageSize = response.data.per_page;
            this.page = response.data.current_page;
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
    },
    formatter: (value) => {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    transform: (value) => {
      const access = value == 0 ? "No" : "Si";
      return access;
    },
    resetForm() {
      this.form = {
        matricula: "",
        nombre: null,
        identificacion: "",
        telefono: null,
        propietario: false,
      };
    },
    onSubmit() {
      if (this.form.matricula == "" && this.form.identificacion == "") {
        alert(
          "Matrícula e identificación no pueden estar vacíos al tiempo, al menos debe diligenciar uno de los 2 campos!"
        );
        return false;
      }

      axios
        .post("/info", {
          matricula: this.form.matricula,
          identificacion: this.form.identificacion,
          nombre: this.form.nombre,
          direccion: this.form.direccion,
          telefono: this.form.telefono,
          propietario: this.form.propietario,
        })
        .then((response) => {
           // this.infousuarios.push(response.data);
            this.resetForm()
            this.refrescar()
          //window.location.href = "info";
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
    },
  },
};
</script>
