<template>
  <div>
    <div class="card">
      <div class="card-header bg-info text-white">Búsqueda de información</div>
      <div class="card-body row">
        <div class="col-md-2 col-sm-12 mb-2">
          <label class="label">Buscar por:</label>
          <select
            class="form-control"
            id="opcbusq"
            name="opcbusq"
            v-model="opcbusq"
            @change="limpiar"
          >
            <option v-for="item of opcSelect" :value="item.id" :key="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-md-8 col-sm-12" v-if="opcbusq <= 2">
          <label class="label">Dato a buscar:</label>
          <input
            type="number"
            placeholder="Buscar por matrícula o medidor..."
            v-model="query"
            class="form-control"
          />
        </div>
        <div class="col-md-10 col-sm-12" v-if="opcbusq > 2">
          <label class="label">Dato a buscar:</label>
          <input
            type="text"
            placeholder="Información a buscar?"
            v-model="query"
            v-on:keyup="autoComplete"
            class="form-control"
          />
          <div class="panel-footer" v-if="results.length">
            <ul class="list-group">
              <li
                class="list-group-item pointer"
                v-for="result in results"
                @click="infodata(result.suscriptor)"
                :key="result.suscriptor"
              >
                {{ result.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2 col-sm-12" v-show="opcbusq <= 2">
          <label class="label">&nbsp;</label>
          <button class="btn btn-success btn-block" @click="search">
            Buscar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      query: "",
      results: [],
      opcbusq: 1,
      opcSelect: [
        { name: "Matrícula", id: 1 },
        { name: "Nombre", id: 3 },
        { name: "Documento", id: 4 },
        { name: "Dirección", id: 5 },
        { name: "Medidor", id: 2 },
      ],
      results: [],
    };
  },
  methods: {
    limpiar() {
      this.query = "";
      this.$emit("data", []);
    },
    autoComplete() {
      this.results = [];
      if (this.query.length > 4) {
        axios
          .get("/suscriptores/buscar", {
            params: { query: this.query, opcbusq: this.opcbusq },
          })
          .then((response) => {
            if (!response.data.error) {
              this.results = response.data;
            } else {
              this.results = [{ name: "Sin resultados", suscriptor: 0 }];
            }
          })
          .catch((error) => {
            alert("Ocurrió un error al consultar los datos!");
          });
      }
    },
    infodata(data) {
      if (data != 0) {
        this.results = [];
        axios
          .get("/suscriptores/buscar", { params: { query: data, opcbusq: 1 } })
          .then((response) => {
            this.$emit("data", response.data);
            this.query = "";
          })
          .catch((error) => {
            alert("Ocurrió un error al consultar los datos!");
          });
      }
    },
    search() {
      this.results = [];
      if (this.query) {
      axios
        .get("/suscriptores/buscar", {
          params: { query: this.query, opcbusq: this.opcbusq },
        })
        .then((response) => {
          if (response.status != 200) {
            alert("No se encontraron datos!");
          } else {
            this.$emit("data", response.data);
            this.query = "";
          }
        })
        .catch((error) => {
          alert("Ocurrió un error al consultar los datos!");
        });
      } else {
          alert("Debe digitar un valor a buscar!");
      }
    },
  },
};
</script>
