<template>
  <div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="d-flex justify-content-between">
        <div>{{ mensaje }}</div>
        <div><b-badge pill variant="light">{{ dismissCountDown }}</b-badge></div>

      </div>
    </b-alert>
  </div>
</template>
<script>
export default {
  props: ["mensaje"],
  data() {
    return {
      dismissSecs: 3,
      dismissCountDown: 0,
    };
  },
  watch: {
    mensaje: function (newVal, oldVal) {
      // console.log("Prop changed: newVal ", newVal, " | was: oldVal ", oldVal);
      if (this.mensaje != "") {
        this.showAlert();
      }
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
